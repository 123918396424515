import React from 'react'

export const GitHubIcon = () => {


    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 0.5C11.3585 0.5 9.73303 0.823322 8.21646 1.45151C6.69989 2.07969 5.3219 3.00043 4.16117 4.16117C1.81696 6.50537 0.5 9.68479 0.5 13C0.5 18.525 4.0875 23.2125 9.05 24.875C9.675 24.975 9.875 24.5875 9.875 24.25V22.1375C6.4125 22.8875 5.675 20.4625 5.675 20.4625C5.1 19.0125 4.2875 18.625 4.2875 18.625C3.15 17.85 4.375 17.875 4.375 17.875C5.625 17.9625 6.2875 19.1625 6.2875 19.1625C7.375 21.0625 9.2125 20.5 9.925 20.2C10.0375 19.3875 10.3625 18.8375 10.7125 18.525C7.9375 18.2125 5.025 17.1375 5.025 12.375C5.025 10.9875 5.5 9.875 6.3125 8.9875C6.1875 8.675 5.75 7.375 6.4375 5.6875C6.4375 5.6875 7.4875 5.35 9.875 6.9625C10.8625 6.6875 11.9375 6.55 13 6.55C14.0625 6.55 15.1375 6.6875 16.125 6.9625C18.5125 5.35 19.5625 5.6875 19.5625 5.6875C20.25 7.375 19.8125 8.675 19.6875 8.9875C20.5 9.875 20.975 10.9875 20.975 12.375C20.975 17.15 18.05 18.2 15.2625 18.5125C15.7125 18.9 16.125 19.6625 16.125 20.825V24.25C16.125 24.5875 16.325 24.9875 16.9625 24.875C21.925 23.2 25.5 18.525 25.5 13C25.5 11.3585 25.1767 9.73303 24.5485 8.21646C23.9203 6.69989 22.9996 5.3219 21.8388 4.16117C20.6781 3.00043 19.3001 2.07969 17.7835 1.45151C16.267 0.823322 14.6415 0.5 13 0.5Z" fill="black"/>
        </svg>
    )
}


