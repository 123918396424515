import React from 'react'

export const NorXWestLogo = ({width = 800, height = 800}) => {
    return (
        <svg width="865" height="865" viewBox="0 0 865 865" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M273.401 159.779L416.59 16.5901C425.377 7.8033 439.623 7.8033 448.41 16.5901L591.599 159.779C600.386 168.566 600.386 182.812 591.599 191.599C582.812 200.386 568.566 200.386 559.779 191.599L455 86.8198V832.5C455 844.926 444.926 855 432.5 855C420.074 855 410 844.926 410 832.5V86.8198L305.221 191.599C296.434 200.386 282.188 200.386 273.401 191.599C264.614 182.812 264.614 168.566 273.401 159.779Z" fill="#2D2D2D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M159.779 591.599L16.5901 448.41C7.8033 439.623 7.8033 425.377 16.5901 416.59L159.779 273.401C168.566 264.614 182.812 264.614 191.599 273.401C200.386 282.188 200.386 296.434 191.599 305.221L86.8198 410H832.5C844.926 410 855 420.074 855 432.5C855 444.927 844.926 455 832.5 455H86.8198L191.599 559.779C200.386 568.566 200.386 582.812 191.599 591.599C182.812 600.386 168.566 600.386 159.779 591.599Z" fill="#2D2D2D"/>
        <path d="M308.756 195.135L405 98.8909V405H98.8909L195.135 308.756C205.874 298.017 205.874 280.605 195.135 269.866C184.395 259.126 166.983 259.126 156.244 269.866L13.0546 413.055C2.31514 423.794 2.31514 441.206 13.0546 451.946L156.244 595.135C166.983 605.874 184.395 605.874 195.135 595.135C205.874 584.395 205.874 566.983 195.135 556.244L98.8909 460H405V832.5C405 847.688 417.312 860 432.5 860C447.688 860 460 847.688 460 832.5V460H832.5C847.688 460 860 447.688 860 432.5C860 417.312 847.688 405 832.5 405H460V98.8909L556.244 195.135C566.983 205.874 584.395 205.874 595.134 195.135C605.874 184.395 605.874 166.983 595.134 156.244L451.945 13.0546C441.206 2.31514 423.794 2.31514 413.055 13.0546L269.865 156.244C259.126 166.983 259.126 184.395 269.865 195.135C280.605 205.874 298.017 205.874 308.756 195.135Z" stroke="white" strokeOpacity="0.2" strokeWidth="10"/>
        </svg>
    )
}
