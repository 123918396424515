import React from 'react';

import "./MessageSentIndicator.css";

export const MessageSentIndicator = () => {

    return (

        <div className='message-sent-container'>
            <svg width="150" height="82" viewBox="0 0 150 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M137.5 0.375H56.25C49.375 0.375 43.75 6 43.75 12.875V69.125C43.75 72.4402 45.067 75.6196 47.4112 77.9638C49.7554 80.308 52.9348 81.625 56.25 81.625H137.5C144.438 81.625 150 76.0625 150 69.125V12.875C150 9.55979 148.683 6.38037 146.339 4.03616C143.995 1.69196 140.815 0.375 137.5 0.375ZM137.5 23.3125L96.875 44.125L56.25 23.3125V12.875L96.875 33.5625L137.5 12.875V23.3125ZM31.25 69.125C31.25 70.1875 31.4375 71.1875 31.5625 72.25H6.25C2.8 72.25 0 69.4375 0 66C0 62.5625 2.8 59.75 6.25 59.75H31.25V69.125ZM18.75 9.75H31.5625C31.4375 10.8125 31.25 11.8125 31.25 12.875V22.25H18.75C15.3125 22.25 12.5 19.4375 12.5 16C12.5 12.5625 15.3125 9.75 18.75 9.75ZM6.25 41C6.25 37.5625 9.0625 34.75 12.5 34.75H31.25V47.25H12.5C9.0625 47.25 6.25 44.4375 6.25 41Z" fill="#14FF00"/>
            </svg>
            <p>Message sent, I will try to get back to you within 1 - 2 business days.</p>
        </div>
    )
}
